<template>
    <div>
        <div class="fixed bottom-0 right-0 top-0 left-0" :class="'z-['+ zIndex + ']'" v-if="modelValue" @wheel="scroll" @touchmove="scroll" @scroll="scroll">
            <div class="bg-black/20 h-full w-full" v-if="overlay" @click="clickOutside"></div>

            <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 max-h-full" :style="{width: width}">
                <div v-if="closable && !crossOutside" class="absolute top-2 right-2 cursor-pointer" @click="close">
                    <icons-x-solid class="w-5 h-5 hover:!text-tangerine" :style="{color: crossColor}"></icons-x-solid>
                </div>

                <div v-else-if="closable && crossOutside" class="absolute cursor-pointer -top-3 -right-6" @click="close">
                    <icons-x-solid class="w-6 h-6 hover:!text-tangerine" :style="{color: crossColor}"></icons-x-solid>
                </div>

                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup>

const props = defineProps({
    modelValue: {},
    overlay: {
        type: Boolean,
        default: true,
        required: false
    },
    width: {
        type: String,
        default: "25vw",
        required: false
    },
    closable: {
        type: Boolean,
        default: true,
        required: false
    },
    persistent: {
        type: Boolean,
        default: false
    },
    crossColor: {
        type: String,
        default: "black",
        required: false,
    },
    crossOutside: {
        type: Boolean,
        default: false
    },
    disableScroll: {
        type: Boolean,
        default: true,
        required: false,
    },
    zIndex: {
        type: String,
        default: '9999'
    }
});

const emits = defineEmits(["update:modelValue", 'close']);

function scroll(event) {
    if (props.disableScroll) {
        event.preventDefault();
    }
}

function close() {
    emits('close');
    emits('update:modelValue');
}

function clickOutside() {
    if (props.persistent === false) {
        close();
    }
}

</script>

<style scoped lang="scss">

</style>
